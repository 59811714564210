import React, { useState, useEffect, useRef } from 'react';
import Web3 from 'web3';

import supabase from './supabase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function FairLaunchDApp() {
    const [web3, setWeb3] = useState(null);
    const [recipientAddress, setRecipientAddress] = useState('');
    const [ethBalance, setEthBalance] = useState('');
    const [tokenAmount, setTokenAmount] = useState('');
    const [tokenForSale, setTokenForSale] = useState('Loading...');
    const [currentRate, setCurrentRate] = useState('10000000');
    const [totalContributor, setTotalContributor] = useState('Loading...');
    const [totalContributor1, setTotalContributor1] = useState('Loading...');
    const [endDate, setEndDate] = useState('Calculating...');
    const [timer, setTimer] = useState('');
    const [showConnectButton, setShowConnectButton] = useState(true);
    const [depositedAmount, setDepositedAmount] = useState('');
    const [withdrawalAmount, setWithdrawalAmount] = useState('');
    const [transactionHash, setTransactionHash] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('ETH'); // Default selected currency
    const [usdtBalance, setUsdtBalance] = useState('');
    const [ethCoin, setEthCoin] = useState('');
    const [usdtCoin, setUsdtCoin] = useState('');
    const [allotedCoin, setAllotedCoin] = useState('');
    const [UsdtAllotedCoin, setUsdtAllotedCoin] = useState('');
    const HOT_WALLET_PRIVATE_KEY = process.env.REACT_APP_HOT_WALLET_PRIVATE_KEY;
    const [totalAmount, setTotalAmount] = useState(null);
    const [UsdtTotalAmount, setUsdtTotalAmount] = useState(null);

    useEffect(() => {
        const initWeb3 = async () => {
            if (typeof window.ethereum !== 'undefined') {
                const web3Instance = new Web3(window.ethereum);
                try {
                    await window.ethereum.enable();
                    const accounts = await web3Instance.eth.getAccounts();
                    if (accounts.length > 0) {
                        setRecipientAddress(accounts[0]);
                        updateWalletStatus(accounts[0]);
                        setWeb3(web3Instance);
                        updateEthBalance(accounts[0]);
                        // updateUsdtBalance(accounts[0]);
                    }
                } catch (error) {
                    console.error(error);
                }
            } else {
                console.log('MetaMask is not installed.');
            }
        };
        initWeb3();
    }, []);

    function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    const reffValue = getParameterByName('reff');
    const fetchDepositedAmount = async () => {
        try {
            // Fetch deposited amount for the connected address from Supabase
            const { data, error } = await supabase
                .from('depositedamount')
                .select('amount')
                .eq('deposit_address', recipientAddress)
                .single();

            if (data) {
                setDepositedAmount(data.amount);
            } else {
                console.log('Error fetching deposited amount:', error);
            }
        } catch (error) {
            console.error('Error fetching deposited amount:', error);
        }
    };

    const fetchAllotedCoin = async () => {
        try {
            // Fetch all records for the connected address from Supabase
            const { data, error } = await supabase
                .from('allotedcoin')
                .select('rate')
                .eq('deposit_address', recipientAddress);
    
            if (error) {
                console.log('Error fetching alloted coin:', error);
                return;
            }
    
            // If data is retrieved
            if (data && data.length > 0) {
                // Sum up the rates for the same deposit address
                const totalRate = data.reduce((sum, record) => sum + record.rate, 0);
                setAllotedCoin(totalRate);
            } else {
                console.log('No data found for the deposit address:', recipientAddress);
            }
        } catch (error) {
            console.error('Error fetching alloted coin:', error);
        }
    };
    

    const fetchEthCoin = async () => {
        try {
            // Fetch all records for the connected address from Supabase
            const { data, error } = await supabase
                .from('allotedcoin')
                .select('amount')
                .eq('deposit_address', recipientAddress);
    
            if (error) {
                console.log('Error fetching eth coin:', error);
                return;
            }
    
            // If data is retrieved
            if (data && data.length > 0) {
                // Sum up the amounts for the same deposit address
                const totalAmount = data.reduce((sum, record) => sum + record.amount, 0);
                setEthCoin(totalAmount);
            } else {
                console.log('No data found for the deposit address:', recipientAddress);
            }
        } catch (error) {
            console.error('Error fetching eth coin:', error);
        }
    };
    
    


    const fetchUsdtAllotedCoin = async () => {
        try {
            // Fetch deposited amount for the connected address from Supabase
            const { data, error } = await supabase
                .from('allotedcoin_usdt')
                .select('rate')
                .eq('deposit_address', recipientAddress)
                .single();

            if (data) {
                setUsdtAllotedCoin(data.rate);
            } else {
                console.log('Error fetching alloted coin:', error);
            }
        } catch (error) {
            console.error('Error fetching alloted coin:', error);
        }
    };

    const fetchUsdtCoin = async () => {
        try {
            // Fetch deposited amount for the connected address from Supabase
            const { data, error } = await supabase
                .from('depositusdt')
                .select('amount')
                .eq('deposit_address', recipientAddress)
                .single();

            if (data) {
                setUsdtCoin(data.amount);
            } else {
                console.log('Error fetching alloted coin:', error);
            }
        } catch (error) {
            console.error('Error fetching alloted coin:', error);
        }
    };


    function updateWalletStatus(statusText) {
        const walletStatusElement = document.getElementById('walletStatus');
        if (walletStatusElement) {
            walletStatusElement.textContent = statusText;
        } else {

        }
    }

    //Pharse 1

    useEffect(() => {
        const fetchTotalContribution = async () => {
            try {
                // Fetch data from the 'deposits' table
                const { data: depositsData, error: depositsError } = await supabase
                    .from('deposits')
                    .select('buycount')
                    .eq('flag', '1');

                if (depositsError) {
                    console.error('Error fetching deposits:', depositsError);
                    setTotalContributor('Error');
                    return;
                }

                // Calculate total contribution by summing up 'buycount' values
                let total = 0;
                if (depositsData && depositsData.length > 0) {
                    depositsData.forEach((deposit) => {
                        total += parseInt(deposit.buycount || 0);
                    });
                } else {
                    // If no data, set total contributor to 0
                    total = 0;
                }

                // Update the state with the calculated total contribution
                setTotalContributor(total);
            } catch (error) {
                console.error('Error fetching total contribution:', error);
                setTotalContributor('Error');
            }
        };

        fetchTotalContribution();
    }, []);



    //Pharse 2
    useEffect(() => {
        const fetchTotalContribution = async () => {
            try {
                // Fetch data from the 'deposits' table
                const { data: depositsData, error: depositsError } = await supabase
                    .from('deposits')
                    .select('buycount')
                    .eq('flag2', '2');

                if (depositsError) {
                    console.error('Error fetching deposits:', depositsError);
                    setTotalContributor1('Error');
                    return;
                }

                // Calculate total contribution by summing up 'buycount' values
                let total = 0;
                if (depositsData && depositsData.length > 0) {
                    depositsData.forEach((deposit) => {
                        total += parseInt(deposit.buycount || 0);
                    });
                } else {
                    // If no data, set total contributor to 0
                    total = 0;
                }

                // Update the state with the calculated total contribution
                setTotalContributor1(total);
            } catch (error) {
                console.error('Error fetching total contribution:', error);
                setTotalContributor1('Error');
            }
        };

        fetchTotalContribution();
    }, []);


    const updateValues = () => {
        setTokenForSale('20000000'); // Replace with actual token amount
        // setCurrentRate('1 ETH = Loading... Tokens'); // Replace with actual rate
        // setTotalContributor('Loading...'); // Replace with actual contributor count

        const endDate = new Date(2023, 11, 31, 23, 59, 59); // 11 represents December
        setEndDate(endDate.toLocaleString());
    };

    useEffect(() => {
        updateValues(); // Initial values on component mount
        const interval = setInterval(updateTimer, 1000); // Update timer every second

        return () => {
            clearInterval(interval); // Clear interval on component unmount
        };
    }, []);

    const updateTimer = () => {
        const endDate = new Date(2023, 11, 31, 23, 59, 59); // 11 represents December

        const now = new Date();
        const timeDiff = endDate - now;

        if (timeDiff > 0) {
            const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

            setTimer(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        } else {
            setTimer('Expired');
        }
    };


    const updateEthBalance = async (account) => {
        if (web3) {
            try {
                const balance = await web3.eth.getBalance(account);
                const etherBalance = web3.utils.fromWei(balance, 'ether'); // Convert balance to Ether
                const ebalanceNumber = Number(etherBalance);
                console.log('Retrieved Ether Balance:', ebalanceNumber); // Log the balance for debugging
                setEthBalance(ebalanceNumber);
            } catch (error) {
                console.error('Error fetching balance:', error);
            }
        } else {
            // console.error('Web3 is not initialized or available.');
        }
    };

    // const usdtContractAddress = '0xdAC17F958D2ee523a2206206994597C13D831ec7';
    // // const usdtContractAddress = '0x7169D38820dfd117C3FA1f22a697dBA58d90BA06';
    // //const usdtContractAddress ='0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';
    // const usdtContractABI = [
    //     {
    //         "constant": true,
    //         "inputs": [],
    //         "name": "balanceOf",
    //         "outputs": [
    //             {
    //                 "name": "",
    //                 "type": "uint256"
    //             }
    //         ],
    //         "payable": false,
    //         "stateMutability": "view",
    //         "type": "function"
    //     }
    // ];



    // const updateUsdtBalance = async (account) => {
    //     if (web3) {
    //         try {
    //             const usdtContract = new web3.eth.Contract(usdtContractABI, usdtContractAddress);
    //             const balance = await usdtContract.methods.balanceOf(account).call();
    //             const balanceNumber = Number(balance); // Convert BigInt to a regular number
    //             console.log('Retrieved USDT Balance:', balanceNumber);
    //             setUsdtBalance(balanceNumber);
    //         } catch (error) {
    //             console.error('Error fetching USDT balance:', error);
    //         }
    //     } else {
    //         // console.error('Web3 is not initialized or available.');
    //     }
    // };

    const ellipsisAddress = (address) => {
        const start = address.substring(0, 7);
        const end = address.substring(address.length - 5);
        return `${start}...${end}`;
    };


    let calculatedTotalAmount = 0;
    const fetchTotalContribution1 = async () => {
        try {
            // Fetch data from the 'deposits' table
            const { data: depositsData, error: depositsError } = await supabase
                .from('deposits')
                .select('amount');

            if (depositsError) {
                console.error('Error fetching deposits:', depositsError);
                return 'Error';
            }

            // Initialize a variable to store the total amount

            if (depositsData && depositsData.length > 0) {
                // Use reduce to sum up all the deposit amounts
                calculatedTotalAmount = depositsData.reduce((acc, deposit) => {
                    return acc + parseFloat(deposit.amount || 0);
                }, 0);
            }

            setTotalAmount(calculatedTotalAmount);
            console.log('Calculated Total Amount:', calculatedTotalAmount);
        } catch (error) {
            console.error('Error fetching total contribution:', error);
            setTotalAmount(0);
        }
    };




    // let calculatedUsdtTotalAmount = 0;
    // const fetchTotalUsdtContribution1 = async () => {
    //     try {
    //         // Fetch data from the 'deposits' table
    //         const { data: depositsData, error: depositsError } = await supabase
    //             .from('depositusdt')
    //             .select('amount');

    //         if (depositsError) {
    //             console.error('Error fetching deposits:', depositsError);
    //             return 'Error';
    //         }

    //         // Initialize a variable to store the total amount

    //         if (depositsData && depositsData.length > 0) {
    //             // Use reduce to sum up all the deposit amounts
    //             calculatedUsdtTotalAmount = depositsData.reduce((acc, depositusdt) => {
    //                 return acc + parseFloat(depositusdt.amount || 0);
    //             }, 0);
    //         }

    //         setUsdtTotalAmount(calculatedUsdtTotalAmount);
    //         console.log('Calculated Usdt Total Amount:', calculatedUsdtTotalAmount);
    //         return calculatedUsdtTotalAmount;
    //     } catch (error) {
    //         console.error('Error fetching total contribution:', error);
    //         setUsdtTotalAmount(0);
    //     }
    // };

    // async function getEthPrice() {
    //     try {
    //         // const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
    //         const response = await fetch('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USDT&api_key=0a57d39ecbbf0428d0c5e0b8db037a50035326dd6718ef4b236f26a1da9ffdee');
    //         const data = await response.json();

    //         if (data && data.ethereum && data.ethereum.usd) {
    //             const ethPrice = data.ethereum.usd;
    //             console.log(ethPrice);
    //             return ethPrice;

    //         } else {
    //             console.error('Error: Unable to fetch ETH price from the API');
    //             return null;
    //         }
    //     } catch (error) {
    //         console.error('Error fetching ETH price:', error);
    //         return null;
    //     }
    // }

    // async function getEthPrice() {
    //     try {
    //         const response = await fetch('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USDT&api_key=0a57d39ecbbf0428d0c5e0b8db037a50035326dd6718ef4b236f26a1da9ffdee');
    //         const data = await response.json();

    //         if (data && data.USDT) {
    //             const ethPrice = data.USDT;
    //             console.log(ethPrice);
    //             return ethPrice;
    //         } else {
    //             console.error('Error: Unable to fetch ETH price from the API');
    //             return null;
    //         }
    //     } catch (error) {
    //         console.error('Error fetching ETH price:', error);
    //         return null;
    //     }
    // }

    // // Call the function
    // getEthPrice();

    // async function calculateUsdtEthRatio() {
    //     try {
    //         const totalUsdtAmount = await fetchTotalUsdtContribution1();
    //         const ethPrice = await getEthPrice();

    //         if (totalUsdtAmount !== 'Error' && ethPrice) {
    //             const usdtEthRatio = totalUsdtAmount / ethPrice;
    //             console.log('USDT / ETH Ratio:', usdtEthRatio);
    //             return usdtEthRatio;
    //         } else {
    //             console.error('Unable to calculate USDT / ETH ratio.');
    //             return null;
    //         }
    //     } catch (error) {
    //         console.error('Error calculating USDT / ETH ratio:', error);
    //         return null;
    //     }
    // }

    // let updatedTotalAmount = 0;

    // async function addTotalAmountWithRatio() {
    //     try {
    //         const totalUsdtAmount = await fetchTotalUsdtContribution1();
    //         const ethPrice = await getEthPrice();

    //         if (totalUsdtAmount !== 'Error' && ethPrice) {
    //             const usdtEthRatio = totalUsdtAmount / ethPrice;
    //             console.log('USDT / ETH Ratio:', usdtEthRatio);

    //             // Add calculatedTotalAmount with usdtEthRatio
    //             const updatedTotalAmount = calculatedTotalAmount + usdtEthRatio;
    //             console.log('Updated Total Amount:', updatedTotalAmount);

    //             // Perform further actions with updatedTotalAmount if needed
    //             // For example: set a state or call a function
    //         } else {
    //             console.error('Unable to calculate USDT / ETH ratio or fetch total contribution.');
    //         }
    //     } catch (error) {
    //         console.error('Error adding total amount with ratio:', error);
    //     }
    // }


    const handleConnectWallet = async () => {
        try {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            const accounts = await web3.eth.getAccounts();
            if (accounts.length > 0) {
                setRecipientAddress(accounts[0]);
                updateWalletStatus(accounts[0]);
                updateEthBalance(accounts[0]);
                setShowConnectButton(false); // Hide connect wallet button
                fetchDepositedAmount();
                // updateUsdtBalance(accounts[0]);
                fetchAllotedCoin();
                // fetchUsdtAllotedCoin();
                // getEthPrice();
                fetchEthCoin();
                // fetchUsdtCoin();
                // calculateUsdtEthRatio();


            }
        } catch (error) {
            console.error(error);
        }
    };



    const depositTokens = async () => {
        const tokenAmount = document.getElementById('tokenAmount').value;

        let coinType = '';

        if (selectedCurrency === 'ETH') {
            coinType = 'ETH';
        } else if (selectedCurrency === 'USDT') {
            coinType = 'USDT';
        }

        if (!recipientAddress || recipientAddress.trim() === '') {
            alert('Please connect your wallet to proceed.');
            return;
        }

        const ownerWalletAddress = "0x8f7D9A37c59210c13728bd9bAdDDE4bd2FF24485";
        // const ownerWalletAddress = "0xd72e48a73389b0569787062E96AeC7C29beCa6Eb";

        try {
            // Fetch the current gas price
            const gasPrice = await web3.eth.getGasPrice();

            // Calculate maxFeePerGas and maxPriorityFeePerGas
            const bufferAmount = 1000000000; // 1 Gwei buffer
            const maxFeePerGas = parseInt(gasPrice) + bufferAmount;
            const maxPriorityFeePerGas = parseInt(gasPrice);

            // Transaction parameters
            const transactionParameters = {
                from: recipientAddress,
                to: ownerWalletAddress,
                value: web3.utils.toWei(tokenAmount, 'ether'),
                gas: '21000', // Set an appropriate gas limit (example: 21,000 for simple transfers)
                maxFeePerGas: maxFeePerGas,
                maxPriorityFeePerGas: maxPriorityFeePerGas
            };

            const pendingDeposit = toast.info('Processing deposit...', { autoClose: false });

            // Perform the transaction
            const transaction = await web3.eth.sendTransaction(transactionParameters);
            const transactionHash = transaction.transactionHash;

            console.log('Transaction Hash:', transactionHash);

            // Insert data into the 'deposits' table
            const tableToInsert = coinType === 'ETH' ? 'deposits' : 'depositusdt';
            const { data: depositsData, error: depositsError } = await supabase.from(tableToInsert).insert([
                {
                    deposit_address: recipientAddress,
                    amount: tokenAmount,
                    trans_hash: transactionHash,
                    owner_address: ownerWalletAddress,
                    coin: coinType,
                    reff: reffValue,
                    buycount: '1',
                    flag2: '2',
                },
            ]);

            if (depositsError) {
                console.error('Error inserting into deposits:', depositsError);
                // Handle error scenario for 'deposits' table
                toast.error('Failed to deposit tokens. Please try again.');
            } else {

                toast.update(pendingDeposit, {
                    render: 'Deposit successful',
                    type: toast.TYPE.SUCCESS,
                    autoClose: 5000, // Auto-close after 5 seconds
                });
                await fetchTotalContribution1();
                // await addTotalAmountWithRatio();
            }

            const totalSupply = 10000000; // Replace this with the actual total supply
            // const totalSupply = 20000000;
            let rate;

            // Calculate rate based on deposited amount and total supply
            if (selectedCurrency === 'ETH') {
                rate = (parseFloat(tokenAmount) / parseFloat(calculatedTotalAmount)) * totalSupply;

                console.log('tokenAmount:', totalAmount);
                console.log('calculatedTotalAmount:', calculatedTotalAmount);
                console.log('rate:', rate);
                setCurrentRate(rate);
                // Check if 'deposit_address' exists in 'allotedcoin' table
                const { data: existingAlloted, error: existingAllotedError } = await supabase
                    .from('allotedcoin')
                    .select('amount', 'rate')
                    .eq('deposit_address', recipientAddress)
                    .eq('flag', '2')
                    .single();

                if (existingAllotedError) {
                    console.error('Error checking existing alloted:', existingAllotedError);
                    // Handle error scenario for existing alloted check
                    const { data: newAllotedData, error: newAllotedError } = await supabase
                        .from('allotedcoin')
                        .insert([
                            {
                                deposit_address: recipientAddress,
                                amount: tokenAmount,
                                rate: rate * 2,
                                flag: '2',
                            },
                        ]);

                    if (newAllotedError) {
                        console.error('Error inserting new alloted:', newAllotedError);
                        // Handle error scenario for insert
                    } else {
                        if (newAllotedData && newAllotedData.length > 0) {
                            console.log('Successfully inserted new alloted:', newAllotedData);
                            // Handle success scenario for insert
                        } else {
                            console.error('Insert operation failed or did not result in new data.');
                            // Handle failure scenario for insert
                        }
                    }
                } else {
                    if (existingAlloted) {
                        // If 'deposit_address' exists, update 'amount' and 'rate'
                        const updatedAmount = parseFloat(existingAlloted.amount) + parseFloat(tokenAmount);
                        const updatedRate = totalSupply * updatedAmount;
                        const { data: updatedData, error: updateError } = await supabase
                            .from('allotedcoin')
                            .update({ amount: updatedAmount, rate: updatedRate })
                            .eq('deposit_address', recipientAddress)
                            .eq('flag', '1');


                        const { data: updatedData1, error: updateError1 } = await supabase
                            .from('allotedcoin')
                            .update({ amount: updatedAmount, rate: updatedRate * 2 })
                            .eq('deposit_address', recipientAddress)
                            .eq('flag', '2');

                        console.log('Updated amount:', updatedAmount);
                        console.log('Updated rate:', updatedRate);
                        if (updateError) {
                            console.error('Error updating amount and rate:', updateError);
                            // Handle error scenario for update
                        } else {
                            console.log('Update operation result:', updatedData);
                            // Handle success scenario for update
                        }
                    } else {
                        // If 'deposit_address' doesn't exist, insert new data into 'allotedcoin'
                        const { data: newAllotedData, error: newAllotedError } = await supabase
                            .from('allotedcoin')
                            .insert([
                                {
                                    deposit_address: recipientAddress,
                                    amount: tokenAmount,
                                    rate: rate * 2,
                                    flag: '2',
                                },
                            ]);

                        if (newAllotedError) {
                            console.error('Error inserting new alloted:', newAllotedError);
                            // Handle error scenario for insert
                        } else {
                            if (newAllotedData && newAllotedData.length > 0) {
                                console.log('Successfully inserted new alloted:', newAllotedData);
                                // Handle success scenario for insert
                            } else {
                                console.error('Insert operation failed or did not result in new data.');
                                // Handle failure scenario for insert
                            }
                        }
                    }
                }
            }
            //  else if (selectedCurrency === 'USDT') {
            //     const ethPrice = await getEthPrice();
            //     rate = (totalSupply / ethPrice) * parseFloat(tokenAmount);

            //     // Check if 'deposit_address' exists in 'allotedcoin' table
            //     const { data: existingAlloted, error: existingAllotedError } = await supabase
            //         .from('allotedcoin_usdt')
            //         .select('amount', 'rate')
            //         .eq('deposit_address', recipientAddress)
            //         .single();

            //     if (existingAllotedError) {
            //         console.error('Error checking existing alloted:', existingAllotedError);
            //         // Handle error scenario for existing alloted check
            //         const { data: newAllotedData, error: newAllotedError } = await supabase
            //             .from('allotedcoin_usdt')
            //             .insert([
            //                 {
            //                     deposit_address: recipientAddress,
            //                     amount: tokenAmount,
            //                     rate: rate,
            //                 },
            //             ]);

            //         if (newAllotedError) {
            //             console.error('Error inserting new alloted:', newAllotedError);
            //             // Handle error scenario for insert
            //         } else {
            //             if (newAllotedData && newAllotedData.length > 0) {
            //                 console.log('Successfully inserted new alloted:', newAllotedData);
            //                 // Handle success scenario for insert
            //             } else {
            //                 console.error('Insert operation failed or did not result in new data.');
            //                 // Handle failure scenario for insert
            //             }
            //         }
            //     } else {
            //         if (existingAlloted) {
            //             // If 'deposit_address' exists, update 'amount' and 'rate'
            //             const updatedAmount = parseFloat(existingAlloted.amount) + parseFloat(tokenAmount);
            //             const updatedRate = totalSupply * updatedAmount;
            //             const { data: updatedData, error: updateError } = await supabase
            //                 .from('allotedcoin_usdt')
            //                 .update({ amount: updatedAmount, rate: updatedRate })
            //                 .eq('deposit_address', recipientAddress);

            //             console.log('Updated amount:', updatedAmount);
            //             console.log('Updated rate:', updatedRate);
            //             if (updateError) {
            //                 console.error('Error updating amount and rate:', updateError);
            //                 // Handle error scenario for update
            //             } else {
            //                 console.log('Update operation result:', updatedData);
            //                 // Handle success scenario for update
            //             }
            //         } else {
            //             // If 'deposit_address' doesn't exist, insert new data into 'allotedcoin'
            //             const { data: newAllotedData, error: newAllotedError } = await supabase
            //                 .from('allotedcoin_usdt')
            //                 .insert([
            //                     {
            //                         deposit_address: recipientAddress,
            //                         amount: tokenAmount,
            //                         rate: rate,
            //                     },
            //                 ]);

            //             if (newAllotedError) {
            //                 console.error('Error inserting new alloted:', newAllotedError);
            //                 // Handle error scenario for insert
            //             } else {
            //                 console.log('Successfully inserted new alloted:', newAllotedData);
            //                 // Handle success scenario for insert
            //             }
            //         }
            //     }
            // }
            // Check if 'deposit_address' exists in 'depositedamount' table
            if (selectedCurrency === 'ETH') {
                const { data: existingDeposit, error: existingDepositError } = await supabase
                    .from('depositedamount')
                    .select('amount')
                    .eq('deposit_address', recipientAddress)
                    .single();


                if (existingDepositError) {
                    console.error('Error checking existing deposit:', existingDepositError);
                    const { data: newDepositData, error: newDepositError } = await supabase
                        .from('depositedamount')
                        .insert([
                            {
                                deposit_address: recipientAddress,
                                amount: tokenAmount,
                                flag: '2',
                            },
                        ]);

                    if (newDepositError) {
                        console.error('Error inserting new deposit:', newDepositError);
                        // Handle error scenario for insert
                    } else {
                        console.log('Successfully inserted new deposit:', newDepositData);
                        // Handle success scenario for insert
                    }
                } else {

                    // If 'deposit_address' exists, update 'amount'
                    const updatedAmount = parseFloat(existingDeposit.amount) + parseFloat(tokenAmount);
                    const { data: updatedData, error: updateError } = await supabase
                        .from('depositedamount')
                        .update({ amount: updatedAmount })
                        .eq('deposit_address', recipientAddress)
                        .eq('flag', '2');

                    console.log('Updated amount:', updatedAmount);
                    if (updateError) {
                        console.error('Error updating amount:', updateError);
                        // Handle error scenario for update
                    } else {
                        console.log('Update operation result:', updatedData);
                        // Handle success scenario for update
                    }
                    console.log(selectedCurrency);
                }
            }
            //  else if (selectedCurrency === 'USDT') {
            //     const { data: existingDeposit, error: existingDepositError } = await supabase
            //         .from('depositedamountusdt')
            //         .select('amount')
            //         .eq('deposit_address', recipientAddress)
            //         .single();


            //     if (existingDepositError) {
            //         console.error('Error checking existing deposit:', existingDepositError);
            //         const { data: newDepositData, error: newDepositError } = await supabase
            //             .from('depositedamountusdt')
            //             .insert([
            //                 {
            //                     deposit_address: recipientAddress,
            //                     amount: tokenAmount,
            //                 },
            //             ]);

            //         if (newDepositError) {
            //             console.error('Error inserting new deposit:', newDepositError);
            //             // Handle error scenario for insert
            //         } else {
            //             console.log('Successfully inserted new deposit:', newDepositData);
            //             // Handle success scenario for insert
            //         }
            //     } else {

            //         // If 'deposit_address' exists, update 'amount'
            //         const updatedAmount = parseFloat(existingDeposit.amount) + parseFloat(tokenAmount);
            //         const { data: updatedData, error: updateError } = await supabase
            //             .from('depositedamountusdt')
            //             .update({ amount: updatedAmount })
            //             .eq('deposit_address', recipientAddress);

            //         console.log('Updated amount:', updatedAmount);
            //         if (updateError) {
            //             console.error('Error updating amount:', updateError);
            //             // Handle error scenario for update
            //         } else {
            //             console.log('Update operation result:', updatedData);
            //             // Handle success scenario for update
            //         }
            //         console.log(selectedCurrency);
            //     }
            // }

            // Further actions with the transaction details can be added

        } catch (error) {
            // Check if the error is due to no rows found
            if (error.code === 'PGRST116') {
                // Handle the case when no rows are found (Insert a new row)
                const { data: newDepositData, error: newDepositError } = await supabase
                    .from('depositedamount')
                    .insert([
                        {
                            deposit_address: recipientAddress,
                            amount: tokenAmount,
                        },
                    ]);

                if (newDepositError) {
                    console.error('Error inserting new deposit:', newDepositError);
                    // Handle error scenario for insert

                } else {
                    console.log('Successfully inserted new deposit:', newDepositData);
                    // Handle success scenario for insert
                }
            } else {
                console.error('Error depositing tokens:', error);
                // Handle other errors
                toast.error('Error depositing tokens. Please try again.');
            }
        }
    };

    // useEffect to fetch total contribution on component mount
    useEffect(() => {
        fetchTotalContribution1();
        // fetchTotalUsdtContribution1();
        // addTotalAmountWithRatio();
    }, []);

    const handleWithdraw = async (event) => {
        event.preventDefault();

        // Select the correct provider URL (Ethereum)
        const ethProviderUrl = "https://eth-mainnet.g.alchemy.com/v2/dobYRnNj5q6QuDxJkGlF_xamksrlpBC7";
        // const ethProviderUrl = "https://eth-sepolia.g.alchemy.com/v2/apZ89M4D0jw68RjdfQcAIjKC6rCNT4iG"
        const providerUrl = ethProviderUrl;

        // Initialize Web3
        const web3 = new Web3(new Web3.providers.HttpProvider(providerUrl));

        // Make sure the private key is loaded from the environment variable
        if (!HOT_WALLET_PRIVATE_KEY) {
            console.error('Environment variable for private key is not set');
            toast.error('Transaction failed. Missing private key.');
            return;
        }

        const pendingToast = toast.info('Withdrawal is pending. Please wait for confirmation.', {
            autoClose: false, // No auto-close
        });

        // Fetch the user's deposited amount from Supabase
        try {
            const { data: depositedData, error: depositedError } = await supabase
                .from('depositedamount')
                .select('amount')
                .eq('deposit_address', recipientAddress)
                .single();

            if (depositedError) {
                console.error('Error fetching deposited amount:', depositedError);
                toast.error('Error fetching deposited amount. Please try again.');
                return;
            }

            const depositedAmount = parseFloat(depositedData.amount);

            // Deduct the withdrawal fee (0.005 ETH)
            const withdrawalFee = 0.10;
            const totalWithdrawAmount1 = depositedAmount
            const totalWithdrawAmount = depositedAmount - withdrawalFee;

            if (totalWithdrawAmount <= 0) {
                toast.warning('Insufficient balance to withdraw after fees.');
                return;
            }

            // Remove any whitespace from the private key
            const sanitizedPrivateKey = HOT_WALLET_PRIVATE_KEY.trim();

            // Ensure the private key has a '0x' prefix
            const finalPrivateKey = sanitizedPrivateKey.startsWith('0x') ? sanitizedPrivateKey : '0x' + sanitizedPrivateKey;

            // Initialize account first
            const account = web3.eth.accounts.privateKeyToAccount(finalPrivateKey);

            // Transaction details
            const tx = {
                from: account.address,
                to: recipientAddress,
                value: web3.utils.toWei(totalWithdrawAmount.toString(), 'ether'),
                gas: 21000,
                gasPrice: await web3.eth.getGasPrice(),
            };

            // Sign and send the transaction
            const signedTx = await web3.eth.accounts.signTransaction(tx, account.privateKey);

            web3.eth.sendSignedTransaction(signedTx.rawTransaction)
                .once('transactionHash', async (hash) => {
                    console.log('Transaction hash:', hash);

                    // Store transaction hash in Supabase (refund table)
                    const { error: refundError } = await supabase
                        .from('refund')
                        .insert({
                            withdraw_address: recipientAddress,
                            amount: totalWithdrawAmount,
                            fee: withdrawalFee,
                            flag: 'PENDING',
                            trans_hash: hash,
                        });

                    if (refundError) {
                        console.error('Error storing transaction hash:', refundError);
                    }

                    // Update the 'withdraw' table with transaction hash and success flag
                    const { error: updateError } = await supabase
                        .from('refund')
                        .update({
                            trans_hash: hash,
                            flag: 'SUCCESS'
                        })
                        .eq('withdraw_address', recipientAddress)
                        .order('created_at', { ascending: false })
                        .limit(1);

                    if (updateError) {
                        console.error('Error updating withdrawal:', updateError);
                    }

                    setTransactionHash(hash); // Store the transaction hash
                })
                .once('receipt', async (receipt) => {
                    // Update the pending toast to success when the transaction is successful
                    toast.update(pendingToast, {
                        render: 'Withdrawal successful',
                        type: toast.TYPE.SUCCESS,
                        autoClose: 5000, // Auto-close after 5 seconds
                    });
                    const updatedDepositedAmount = depositedAmount - totalWithdrawAmount1;

                    const { error: updateDepositedError } = await supabase
                        .from('depositedamount')
                        .update({ amount: updatedDepositedAmount })
                        .eq('deposit_address', recipientAddress);

                    if (updateDepositedError) {
                        console.error('Error updating deposited amount:', updateDepositedError);
                        toast.error('Transaction failed. Please try again later.');
                    } else {
                        const { data: existingAlloted, error: existingAllotedError } = await supabase
                            .from('allotedcoin')
                            .select('rate')
                            .eq('deposit_address', recipientAddress)
                            .single();

                        if (existingAllotedError) {
                            console.error('Error fetching existing alloted:', existingAllotedError);
                            // Handle error scenario for fetching existing alloted
                        } else if (existingAlloted) {
                            const currentRate = existingAlloted.rate || 0;
                            const updatedRate = currentRate - currentRate;

                            const { error: updateAllotedError } = await supabase
                                .from('allotedcoin')
                                .update({ rate: updatedRate, amount: updatedDepositedAmount })
                                .eq('deposit_address', recipientAddress);

                            if (updateAllotedError) {
                                console.error('Error updating alloted coin rate:', updateAllotedError);
                                // Handle error scenario for updating alloted coin rate
                            } else {
                                console.log('Successfully updated alloted coin rate');
                                // Handle success scenario for updating alloted coin rate
                            }
                        }
                    }
                    console.log('Transaction receipt:', receipt);
                    // Handle transaction receipt if needed
                })
                .once('error', (error) => {
                    console.error('Transaction failed:', error);
                    toast.error('Transaction failed. Please try again later.');
                });
        } catch (error) {
            console.error('Error fetching deposited amount:', error);
            toast.error('Transaction failed. Please try again later.');
        }
    };

    if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
        // MetaMask is installed and open, hide "Connect To Metamask" and show "Connect Wallet"
        const connectMetamaskButton = document.getElementById('connectMetamaskButton');
        const connectWalletButton = document.getElementById('connectWalletButton');

        if (connectMetamaskButton) {
            connectMetamaskButton.style.display = 'none';
            // Disable the link when showing the button
            const linkButton = connectWalletButton.querySelector('.link-button');
            if (linkButton) {
                linkButton.setAttribute('disabled', true);
            }
        }

        if (connectWalletButton) {
            connectWalletButton.style.display = 'block';

        }
    }


    return (
        <div>
            <ToastContainer />
            <nav>
                <div className="container">
                    <h1><span className="fair">Fair</span> Launch</h1>
                    <div id="timer">{timer}.</div>
                    {showConnectButton ? (
                        <>
                            <button id="connectWalletButton" onClick={handleConnectWallet}>Connect Wallet</button>
                            <button id="connectMetamaskButton" onClick={handleConnectWallet}>
                                <a href={`https://metamask.app.link/dapp/presale.bossblockchain.world`} className="link-button">
                                    Connect Metamask
                                </a>
                            </button>
                        </>
                    ) : (

                        <p id="walletStatus">{`${ellipsisAddress(recipientAddress)}`}</p>



                    )}
                </div>
            </nav >
            <div className="container1">
                <p>$BBC for Sale: <span id="tokenForSale">{tokenForSale}</span> (Phase-2)</p>
                {/* <p>Current Rate: <span id="currentRate"> 1 Eth = {rate1} $BBC</span></p> */}
                <p>Total Contributor: <span id="totalContributor">{totalContributor}</span> P-1</p>
                <p>Total Contributor: <span id="totalContributor1">{totalContributor1}</span> P-2</p>
                <p>Ends By: <span id="endDate">{endDate}</span></p>
                <div className="input-container1">
                    <p>Choose your Coin :</p>
                    <select value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)}>
                        <option value="ETH">ETH</option>
                        {/* <option value="USDT">USDT</option> */}
                    </select>
                </div>


                {selectedCurrency === 'ETH' && (
                    <>
                        <div className="input-container">
                            <input
                                type="number"
                                id="tokenAmount"
                                placeholder="Enter ETH amount"
                                value={tokenAmount}
                                onChange={(e) => setTokenAmount(e.target.value)}
                            />
                            <button onClick={depositTokens}>Buy</button>
                        </div>
                        <p id="ethBalance">{`ETH Balance: ${ethBalance || '0'} ETH`}</p>
                        {depositedAmount !== null && depositedAmount !== '' && (
                            <>
                                <p id="ethDepositedAmount">{`Your Contribution (ETH): ${ethCoin || '0'} ETH`}</p>
                                <p id="ethDepositedAmount">{`Your P1+P2 $BBC : ${allotedCoin || '0'}`}</p>
                                {/* <div className="container2">
                    <p className="caution">Note: A fee of 10% will be deducted for emergency withdrawal.</p>
                    <button onClick={handleWithdraw} className='emer'>Emergency Withdraw</button>
                </div> */}
                            </>
                        )}
                    </>
                )}

            </div>
        </div >

    );
}

export default FairLaunchDApp;
