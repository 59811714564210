
import './App.css';
import FairLaunchDApp from './components/main';

function App() {
  return (
    <div >
      <FairLaunchDApp/>
    </div>
  );
}

export default App;
